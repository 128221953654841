import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { AiFillCheckCircle } from "react-icons/ai"

const ProductCycloneTcp = () => (
  <Layout>
    <Seo title="CycloneTCP" />
    <main>
      {/* TITLE */}
      <section>
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">CycloneTCP</h1>
                <p className="lead fw-normal text-muted mb-4">
                  組み込み専用IPv4/IPv6ネットワークスタック
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Jumbotron */}
      <section className="bg-dark">
        <div className="container">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="py-lg-5 py-4">
                <img
                  className="img-fluid rounded-3"
                  src="/images/product_cyclonetcp/OryxEmbedded-Jumbotron.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row py-lg-5 py-4">
                <h1
                  className="py-lg-5 text-center text-lg-start"
                  style={{
                    color: "#fff",
                    textShadow: "0 0 20px rgba(255,255,255,0.5)",
                  }}
                >
                  IoT社会を支える
                  <br />
                  組み込み専用
                  <br />
                  ネットワーク
                  <br />
                  ソリューション
                  <br />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature1 */}
      <section className="py-lg-5" id="features">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="fw-bolder text-gradient">CycloneTCP</h2>
              <h4 className="mt-lg-5">
                組み込み専用デュアル
                <br />
                ネットワークスタック
              </h4>
            </div>
            <div className="col-lg-8">
              <div className="row gx-5 row-cols-1 row-cols-md-3">
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;強力なネットワーク機能
                  </h2>
                  <div>
                    <ul>
                      <li>IPv4/IPv6デュアルスタック</li>
                      <li>複数ネットワークインタフェースのサポート</li>
                      <li>BSDソケットインタフェースAPI</li>
                      <li>ゼロコピー</li>
                      <li>各種イーサネットドライバ完備</li>
                    </ul>
                  </div>
                </div>
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;多数のプロトコルサポート
                  </h2>
                  <p className="mb-0">
                    <ul>
                      <li>HTTP/2, WebSocket, MQTT v3.1.1, MQTT-SN, CoAP</li>
                      <li>
                        DNS, NetBIOS, LLMNR, mDNS, DNS-SD, DHCP, Auto-IP,
                        DHCPv6, SLAAC, IGMPv2 and MLDv1
                      </li>
                      <li>FTP/FTPS, HTTP/HTTPS, SMTP, SNTP, SNMP, TFTP</li>
                      <li>Modbus/TCP, PPP</li>
                    </ul>
                  </p>
                </div>
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;RFC準拠リスト
                  </h2>
                  <p className="mb-0">
                    詳細はこちらをご覧ください。
                    <br />
                    <a
                      href="https://oryx-embedded.com/products/CycloneTCP.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      RFC準拠リスト
                    </a>
                  </p>
                </div>
                <div className="col h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;様々なOS対応
                  </h2>
                  <p className="mb-0">
                    <ul>
                      <li>FreeRTOS, OPENRTOS, SAFERTOS</li>
                      <li>embOS, ThreadX, CMSIS-RTOS</li>
                      <li>uC/OS-III</li>
                    </ul>
                  </p>
                </div>
                <div className="col mb-5 mb-md-0 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;様々なプロセッサ対応
                  </h2>
                  <p className="mb-0">
                    <ul>
                      <li>Cortex-M, Cortex-R, Cortex-A</li>
                      <li>RX600</li>
                      <li>RISC-V, MIPS</li>
                      <li>PowerPC e200</li>
                      <li>Xtensa LX6</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature1  */}
      <section className="bg-light">
        <div className="container">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="row py-lg-5 py-4">
                <img
                  className="img-fluid rounded-3"
                  src="/images/product_cyclonetcp/CycloneTCP-ipv4-ipv6-osi-model.svg"
                  alt="stm32f750-discovery-1"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row py-lg-5 py-4">
                <h1 className="py-lg-5 text-center text-lg-start">
                  <br />
                  イーサネット通信から
                  <br />
                  セキュア通信まで
                  <br />
                  <br />
                  ワンストップ
                  <br />
                  ソリューション。
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature2 */}
      <section className="py-lg-5" id="features">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="fw-bolder text-gradient">CycloneSSL</h2>
              <h4 className="mt-lg-5">
                組み込み専用
                <br />
                TLS/DTLSライブラリ
              </h4>
            </div>
            <div className="col-lg-8">
              <div className="row gx-5 row-cols-1 row-cols-md-2">
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;様々なアルゴリズムサポート
                  </h2>
                  <div>
                    <ul>
                      <li>
                        ハッシュ関数(SHA-2, SHA-3, BLAKE, Whirlpool, SHAKE)
                      </li>
                      <li>メッセージ認証コード(HMAC, CMAC, GMAC, KMAC)</li>
                      <li>
                        ストリーム、ブロック暗号(Triple DES, AES, Camellia,
                        SEED)
                      </li>
                      <li>デジタル署名(DSA, ECDSA)</li>
                      <li>PKCS#1, #3, #5, #8</li>
                      <li>公開鍵交換(ECDH)</li>
                      <li>公開鍵基板(PKI)</li>
                    </ul>
                  </div>
                  <p className="mb-0">
                    暗号化スイートについてはこちらをご覧ください。
                    <br />
                    <a
                      href="https://oryx-embedded.com/products/CycloneSSL.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      暗号アルゴリズム、暗号化スイートのリスト
                    </a>
                  </p>
                </div>
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;RFC、NIST準拠リスト
                  </h2>
                  <p className="mb-0">
                    詳細はこちらをご覧ください。
                    <br />
                    <a
                      href="https://oryx-embedded.com/products/CycloneSSL.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      RFC、NIST準拠リスト
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature2  */}
      <section className="bg-light">
        <div className="container">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="row py-lg-5 py-4">
                <img
                  className="img-fluid rounded-3"
                  src="/images/product_cyclonetcp/CycloneSSL-ssl-tls-dtls-osi-model.svg"
                  alt="stm32f750-discovery-1"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row py-lg-5 py-4">
                <h1 className="py-lg-5 text-center text-lg-start">
                  セキュア通信
                  <br />
                  TLS 1.3、DTLS 1.2対応
                  <br />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature3 */}
      <section className="py-lg-5" id="features">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="fw-bolder text-gradient">CycloneCrypto</h2>
              <h4 className="mt-lg-5">
                組み込み専用
                <br />
                暗号化ライブラリ
              </h4>
            </div>
            <div className="col-lg-8">
              <div className="row gx-5 row-cols-1 row-cols-md-2">
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;様々なアルゴリズムサポート
                  </h2>
                  <div>
                    <ul>
                      <li>
                        ハッシュ関数(SHA-2, SHA-3, BLAKE, Whirlpool, SHAKE)
                      </li>
                      <li>メッセージ認証コード(HMAC, CMAC, GMAC, KMAC)</li>
                      <li>
                        ストリーム、ブロック暗号(Triple DES, AES, Camellia,
                        SEED)
                      </li>
                      <li>公開鍵暗号(ECDH)</li>
                      <li>公開鍵証明(ECDSA)</li>
                    </ul>
                    暗号化スイートについてはこちらをご覧ください。
                    <br />
                    <a
                      href="https://oryx-embedded.com/products/CycloneCRYPTO.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      暗号アルゴリズム、暗号化スイートのリスト
                    </a>
                  </div>
                </div>
                <div className="col mb-5 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;暗号化アクセラレータサポート
                  </h2>
                  <p className="mb-0">
                    詳細はこちらをご覧ください。
                    <br />
                    <a
                      href="https://oryx-embedded.com/benchmark/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      対応MCU、ベンチマークテストの結果
                    </a>
                  </p>
                </div>
                <div className="col mb-5 mb-md-0 h-100">
                  <h2 className="h5 fw-bold">
                    <AiFillCheckCircle />
                    &nbsp;楕円曲線暗号サポート
                  </h2>
                  <p className="mb-0">
                    パラメータの種類についてはこちらをご覧ください。
                    <br />
                    <a
                      href="https://oryx-embedded.com/products/CycloneCRYPTO.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ECCパラメータの種類
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature3  */}
      <section className="bg-light">
        <div className="container">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="row py-lg-5 py-4">
                <img
                  className="img-fluid rounded-3"
                  src="/images/product_cyclonetcp/crypto-algorithms-cloud.png"
                  alt="stm32f750-discovery-1"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row py-lg-5 py-4">
                <h1 className="py-lg-5 text-center text-lg-start">
                  強力な
                  <br />
                  暗号化アルゴリズム技術
                  <br />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FAQ */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-xl-12">
              <div className="card border-0 bg-light mt-xl-5">
                <div className="card-body p-4 py-lg-5">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-left">
                      <div className="h4">
                        製品に関するお問い合わせは
                        <br />
                        メールにてご相談ください。
                      </div>
                      <p className="text-muted mb-4">
                        <a href="mailto:dioiz@dioiz.com?subject=Embedded Wizardに関するお問い合わせ&amp;cc=kmchoi@dioiz.com">
                          dioiz@dioiz.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default ProductCycloneTcp
